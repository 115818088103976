body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root {
  height: 100%;
  background: #151515;
}

* {
  box-sizing: border-box;
}

h1 {
  margin: 0 0 15px;
  font-size: 2em;
  line-height: 1em;
}

.App {
  width: 100%;
  height: 100%;
  color: #ccc;
  font: 16px Arial, sans-serif;
  line-height: 1.2em;
}

header {
  width: 100%;
  padding: 10px;
  text-align: center;
}

#logo {
  width: 80px;
  height: auto;
}

.container {
  left: 0;
  right: 0;
  width: 1200px;
  min-height: 80%;
  margin: 0 auto 30px;
  border-radius: 5px;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.row-item {
  margin-right: 10px;
}

.icon-sm {
  width: auto;
  height: 16px;
  margin-right: 7px;
  vertical-align: top;
}

.QuestList {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 0 0 250px;
          flex: 0 0 250px;
  background: #2d2d2d;
  border-right: 2px solid #222;
}

.quest-tabs-bar {
  font-size: 13px;
}

.quest-tabs-bar div {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 12px 12px 12px;
  border-bottom: 2px solid #222;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.tab-selected {
  border-bottom: 4px solid #ccc !important;
  margin-bottom: 0;
  color: #fff;
}

.quest-tabs-bar div:hover {
  margin-bottom: 0;
  color: #fff;
}

.button {
  display: inline-block;
  font-size: 14px;
  line-height: 1.2em;
  cursor: pointer;
}
.button:hover {
  display: inline-block;
  color: #fff;
  cursor: pointer;
}

.right {
  float: right;
}

.QuestList ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.QuestList li {
  padding: 12px 15px;
  cursor: pointer;
}

.side-button {
  padding: 13px 15px;
  background: #262626;
  font-size: 14px;
  line-height: 1.2em;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.side-button:hover {
  color: #fff;
}

.QuestList li:hover {
  background: rgba(255,255,255,0.03);
}

.QuestList .list-section-head {
  padding: 10px 15px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  font-weight: bold;
  cursor: default;
}

.QuestList .message {
  padding: 10px 15px;
  color: #555;
  font-style: italic;
}

.selected, .selected:hover {
  background: rgba(255,255,255,0.08) !important;
}

.Welcome {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 18px 18px 10px;
  background: #2d2d2d;
  font-size: 14px;
}

.Welcome form {
  margin: 20px 0;
}

.Welcome input[type='text'] {
  width: 300px;
  padding: 8px 0;
  border: 0;
  border-bottom: 1px solid #555;
  margin: 10px 0;
  background: transparent;
  color: #fff;
  font-size: 16px;
  outline: none;
}
.Welcome input[type='text']:focus {
  border-color: #aaa;
}

.Welcome input[type='submit'] {
  padding: 10px 15px;
  border: 1px solid #aaa;
  margin: 5px 0;
  background: #222;
  color: #fff;
  cursor: pointer;
}
.Welcome input[type='submit']:hover {
  background: #111;
}

.QuestDetails {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 80vh;
  padding: 18px 18px 10px;
  background: #2d2d2d;
}

.input {
  padding-bottom: 5px;
  border: 0;
  border-bottom: 1px solid #444;
  background: rgba(0,0,0,0);
  color: #ccc;
  font: 16px Arial, sans-serif;
}

.quest-title-input {
  width: 100%;
  font-size: 18px;
  outline: none;
}
.quest-title-input:focus {
  border-bottom: 1px solid #aaa;
}

.quest-time-info {
  width: 100%;
  padding: 7px 0;
  font-size: 12px;
  color: #555;
  border-bottom: 1px solid #444;
}

.timestamp {
  color: #777;
}

.quest-details {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  resize: none;
  outline: none;
  overflow: hidden;
}

.quest-options-bar {
  width: 100%;
  padding: 10px 0 3px;
  line-height: 1.1em;
}

.Overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.overlay-shade {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  pointer-events: auto;
}

.overlay-panel {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 20px;
  -webkit-align-self: center;
          align-self: center;
  background: #222;
  border: 1px solid #777;
  border-radius: 5px;
  text-align: center;
  pointer-events: auto;
}

.overlay-panel p {
  margin: 0;
}

.overlay-button {
  min-width: 80px;
  margin: 15px 5px 0;
  padding: 8px 15px;
  background: #222;
  color: #ccc;
  border: 1px solid #777;
  font: 16px Arial, sans-serif;
  cursor: pointer;
  outline: none;
}
.overlay-button:hover {
  background: #111;
}
